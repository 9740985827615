import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import axios from 'axios'


export default function CommingSoon() {

    const navigate = useNavigate()
    const handelBackToHome = () => {
        navigate('/')
    }


    // const data = {
    //     name: 'Vikas',
    //     amount: 1,
    //     mobile: '9876543210',
    // }

    // const handlePayment = async (e) => {
    //     e.preventDefault();

    //     try {
    //         const res = await axios.post('http://localhost:5000/api/transaction', data);

    //         console.log(res);

    //         // Ensure that the necessary properties exist before accessing them
    //         if (res.data && res.data.data && res.data.data.instrumentResponse && res.data.data.instrumentResponse.redirectInfo && res.data.data.instrumentResponse.redirectInfo.url) {
    //             window.location.href = res.data.data.instrumentResponse.redirectInfo.url;
    //         }
    //     } catch (error) {
    //         // Handle specific error types or log the error
    //         console.error('Error:', error);
    //     }
    // };







    return (
        <>
            <div className='py-5'>
                <Container>
                    <Row className='align-items-center justify-content-center mb-5'>
                        <Col lg={6} className='text-center'>
                            <h4>Comming Soon.....</h4>
                        </Col>
                    </Row>
                    <Row className='align-items-center justify-content-center'>
                        <Col lg={6} className='text-center'>
                            <Button className='btn order-btn' onClick={handelBackToHome}>Back To Home</Button>
                            {/* <Button className='btn order-btn' onClick={handlePayment}>Payment</Button> */}
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

